import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ServiceCard = ({ data }) => (
  <article className="service-card">
    {data.frontmatter.featuredImage ? 
      (
        <Link to={data.frontmatter.slug}>
          <Img 
            fixed={data.frontmatter.featuredImage.childImageSharp.fixed} 
            objectFit= "none"
            objectPosition="center center"
            alt={data.frontmatter.title + ' - Featured image'}
            className="featured-image"
          />
        </Link>
      ) : ""
    }
    <div className="service-content">
      <h2 className="title"><Link to={data.frontmatter.slug}>{data.frontmatter.title}</Link></h2>
    </div>
  </article>
)

export default ServiceCard